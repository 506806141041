export default function Icond1() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M8.70296 17.0732L17.1777 24.974C17.8151 25.5707 18.8428 25.563 19.4683 24.9564L25.0376 19.5559C25.6531 18.959 25.665 17.9758 25.0641 17.3616L14.862 6.93276L15.2323 7.08852H8.2359C7.37874 7.08852 6.68562 7.78329 6.68562 8.64621V15.3762C6.68562 15.6623 6.45371 15.8942 6.16764 15.8942C5.88157 15.8942 5.64966 15.6623 5.64966 15.3762V8.64621C5.64966 7.2117 6.80604 6.05255 8.2359 6.05255H15.2323C15.3717 6.05255 15.5051 6.1087 15.6026 6.20831L25.8047 16.6371C26.8065 17.6612 26.7867 19.3028 25.7588 20.2996L20.1895 25.7001C19.1668 26.6918 17.5102 26.7043 16.4705 25.731L7.99653 17.831C7.78728 17.6359 7.77579 17.3081 7.97087 17.0989C8.16594 16.8897 8.49371 16.8782 8.70296 17.0732ZM11.0885 13.8223C9.80114 13.8223 8.75755 12.7787 8.75755 11.4914C8.75755 10.204 9.80114 9.16044 11.0885 9.16044C12.3758 9.16044 13.4194 10.204 13.4194 11.4914C13.4194 12.7787 12.3758 13.8223 11.0885 13.8223ZM11.0885 12.7863C11.8036 12.7863 12.3834 12.2065 12.3834 11.4914C12.3834 10.7762 11.8036 10.1964 11.0885 10.1964C10.3733 10.1964 9.79351 10.7762 9.79351 11.4914C9.79351 12.2065 10.3733 12.7863 11.0885 12.7863Z"
                fill="black"
                stroke="black"
                strokeWidth="0.2"
            />
            <path d="M22 17.2L17.2 22" stroke="black" strokeWidth="1.2" strokeLinecap="round" />
            <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="black" />
        </svg>
    );
}
